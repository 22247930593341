<template >
  <HeaderComponent />

<section class=" py-5">
<div class="container">
    <div class="row ">
        <div class="col-lg-3 col-xl-2 ">
          <NavComponent />
            
        </div>
        <div class="col-lg-9 col-xl-10 ">
         <router-view />
           
        </div>
    </div>
</div>
</section>

<FooterComponent />


  <!-- <nav>
    <router-link class="btn btn-success" to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
   -->
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import NavComponent from "@/components/NavComponent";
import FooterComponent from "@/components/FooterComponent";
export default {
  components: {
    HeaderComponent,
    NavComponent,
    FooterComponent
  },
};
</script>
<style lang="scss">

</style>
