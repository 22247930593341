<template>
    <div class="options">
<div class="row align-items-center justify-content-between pb-3 mt-4 mt-lg-0">
                <div class="col-auto">
                    <h1 class="title-page">اعدادات مسار العمل لـ "<span class="fs-20 text-primary">{{form.general_data.title}}</span>"</h1>
                </div>
                <div class="col-auto">
                    
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div class="form-code small">Version: <b>{{form.general_data.version}}</b></div>
                <div class="form-code small">Code: <b>{{form.general_data.code}}</b></div>
            </div>
            <div class=" mb-3">



  <div class="row align-items-start gx-3">
  <div class="col-lg-3 nav flex-column nav-pills bg-white p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <button  v-for=" (tab , index) in form.workflow.steps" :index="index" :key="tab"   :class=" index == 0 ? 'active ' + tab.type :  tab.type "  class="nav-link text-end p-3 m-0" data-bs-toggle="pill" :data-bs-target="'#v-pills-step'+index" type="button" role="tab" :aria-controls="'v-pills-step'+index" :aria-selected="index == 0 ? 'true' : 'false'">{{tab.title}}</button>
  </div>
  <div class="col tab-content card p-4 br-0-i" id="v-pills-tabContent">
    <div  v-for=" (tab , index) in form.workflow.steps" :index="index" :key="tab"  :class=" index == 0 ? 'show active ' + tab.type : tab.type " class="tab-pane fade  " :id="'v-pills-step'+index" role="tabpanel" :aria-labelledby="'v-pills-step'+index+'-tab'">

<div class=""  v-if="tab.type == 'approval'">
    <div class="qoustion mb-4 bg-light p-2">
    <div class="mb-2 text-bold">{{tab.options.when_aproval.label}}</div>
  
    <div class="form-check form-check-inline" v-for="(choice , i)  in tab.options.when_aproval.choices"  :index="i" :key="choice">
    <input class="form-check-input" type="radio" :name="'choice_'+index" :id="'choicee'+index+'_'+i" :value="choice.value" v-model="tab.options.when_aproval.selected" :checked="i === 0">
    <label class="form-check-label" :for="'choicee'+index+'_'+i">{{choice.title}}</label>
    </div>

  <div v-if="tab.options.when_aproval.selected =='1'">
    <VueMultiselect  
    v-model="tab.options.when_aproval.choices[1].emails"
    :options="options"
    :multiple="true"
    :close-on-select="true"
    placeholder="اختر المستخدمين"
    label="name"
    track-by="name" />
  </div>
    </div>

    <div class="qoustion mb-4 bg-light p-2">
    <div class="mb-2 text-bold">{{tab.options.when_not_aproval.label}}</div>
  
    <div class="form-check form-check-inline" v-for="(choice , i)  in tab.options.when_not_aproval.choices"  :index="i" :key="choice">
    <input class="form-check-input" type="radio" :name="'choice_not_'+index" :id="'choice'+index+'_not_'+i" :value="choice.value" v-model="tab.options.when_not_aproval.selected" :checked="i === 0">
    <label class="form-check-label" :for="'choice'+index+'_not_'+i">{{choice.title}}</label>
    </div>

  <div v-if="tab.options.when_not_aproval.selected =='1'">
    <VueMultiselect  
    v-model="tab.options.when_not_aproval.choices[1].emails"
    :options="options"
    :multiple="true"
    :close-on-select="true"
    placeholder="اختر المستخدمين"
    label="name"
    track-by="name" />
  </div>
    </div>




</div>
<div class=""  v-if="tab.type == 'note'">

   <div class="qoustion mb-4 bg-light p-2">
    <div class="mb-2 text-bold">{{tab.options.when_add_note.label}}</div>
  
    <div class="form-check form-check-inline" v-for="(choice , i)  in tab.options.when_add_note.choices"  :index="i" :key="choice">
    <input class="form-check-input" type="radio" :name="'choice_'+index" :id="'choicee'+index+'_'+i" :value="choice.value" v-model="tab.options.when_add_note.selected" :checked="i === 0">
    <label class="form-check-label" :for="'choicee'+index+'_'+i">{{choice.title}}</label>
    </div>

  <div v-if="tab.options.when_add_note.selected =='1'">
    <VueMultiselect  
    v-model="tab.options.when_add_note.choices[1].emails"
    :options="options"
    :multiple="true"
    :close-on-select="true"
    placeholder="اختر المستخدمين"
    label="name"
    track-by="name" />
  </div>
    </div>
    

   <div class="qoustion mb-4 bg-light p-2">
    <div class="mb-2 text-bold">{{tab.options.when_not_add_note.label}}</div>
  
    <div class="form-check form-check-inline" v-for="(choice , i)  in tab.options.when_not_add_note.choices"  :index="i" :key="choice">
    <input class="form-check-input" type="radio" :name="'choice_not_'+index" :id="'choice'+index+'_not_'+i" :value="choice.value" v-model="tab.options.when_not_add_note.selected"  :checked="i === 0">
    <label class="form-check-label" :for="'choice'+index+'_not_'+i">{{choice.title}}</label>
    </div>

  <div v-if="tab.options.when_not_add_note.selected =='1'">
    <VueMultiselect  
    v-model="tab.options.when_not_add_note.choices[1].emails"
    :options="options"
    :multiple="true"
    :close-on-select="true"
    placeholder="اختر المستخدمين"
    label="name"
    track-by="name" />
  </div>
    </div>

</div>

    <div class="qoustion mb-4 bg-light p-2">
    <div class="mb-2 text-bold">{{tab.options.reminder.label}}</div>
  
    <div class="form-check form-check-inline" v-for="(choice , i)  in tab.options.reminder.choices"  :index="i" :key="choice">
    <input class="form-check-input" type="checkbox" :name="'choice_reminder_'+index" :id="'choice'+index+'_reminder_'+i" :value="choice.value" v-model="tab.options.reminder.selected">
    <label class="form-check-label" :for="'choice'+index+'_reminder_'+i">{{choice.title}}</label>
    </div>

    </div>


    <div class="qoustion mb-4 bg-light p-2">
    <div class="mb-2 text-bold">{{tab.options.cc.label}}</div>
    <div>
        <input type="text" class=" form-control" v-model="tab.options.cc.email">
    </div>

    </div>


    </div>
  </div>
</div>


            </div>
              <div class="d-flex align-items-center justify-content-center">
           <button @click="changeStatusStep('[1]')" class="btn btn-primary mx-1">السابق</button>
          <button @click="changeStatusStep('[1,2,3]')" class="btn btn-success mx-1">التالي</button>
         </div>
    </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ /* options */ });
import VueMultiselect from "vue-multiselect";
export default {
    name:'TheOptions',
     props:["FormHome"],
    data (){
        return {
            form : this.FormHome,
               options: [
        { name: 'صالح حبوش', email: 'saleh@mtc.ps' },
        { name: 'Osama almadhon', email: 'osama@mtc.ps' },
        { name: 'Mohmmed Khaled', email: 'mohmmed@mtc.ps' },
        { name: 'Ahmed Saed', email: 'ahmed@mtc.ps' },
        { name: 'Tareq dokhan', email: 'tareq@mtc.ps' },
        { name: 'Rasim moshtaha', email: 'rasim@mtc.ps' }
      ],
        }
    }, 
    components:{
     VueMultiselect 
    },
    


  methods:{
    changeStatusStep(statuss) {

  for (const item of this.form.workflow.steps) {
              if(item.haveEmpty == 'yes'){
                console.log(item.haveEmpty);
                statuss = '[1]'
                this.$emit('changeStatusStep' , statuss)
                toaster.success(`تم تخزين اعدادات المسار العمل بنجاح`);

           
            }
            else{
               this.$emit('changeStatusStep' , statuss)
            }
        }

      

    },

  }
}
</script>
