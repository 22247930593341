<template>
  <div class="form-component">
        <!-- slide-left -->
        <!--  -->
        <div id="slide-left"  :class="{ show: toggle }">
            <span  @click="toggle = !toggle"
   id="btn-slide">
                
<svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216    v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z" style="&#10;    fill: #fff;&#10;"/>
	</g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
	<g>
</g>
</svg>
  </span>
            <div class="content-slide">
              <ul class="nav nav-pills py-2" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="add_filed-tab" data-bs-toggle="tab" data-bs-target="#add_filed" type="button" role="tab" aria-controls="add_filed" aria-selected="true">اضافة حقول</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="setting-tab" data-bs-toggle="tab" data-bs-target="#setting" type="button" role="tab" aria-controls="setting" aria-selected="false">الاعدادات</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="style_form-tab" data-bs-toggle="tab" data-bs-target="#style_form" type="button" role="tab" aria-controls="style_form" aria-selected="false">الستايل</button>
  </li>
    <li class="nav-item" role="presentation">
    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#template_form" type="button" role="tab" aria-controls="template_form" aria-selected="false">القوالب</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane p-3 fade show active" id="add_filed" role="tabpanel" aria-labelledby="add_filed-tab">
    <div class="title-tab-custom">اضافة حقول</div>
    <div class="text-helper">يمكنك من هنا اضافة الحقول المطلوبة</div>
    <div class="content-tab-custom mt-3">
      <div class="row g-3">
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('text')">
                <div class="icon-input"><img src="@/assets/form/text.svg" alt=""></div>
                <div class="title-input mx-1">حقل نصي قصير</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input"  @click="addShortText('number')">
                <div class="icon-input"><img src="@/assets/form/number.svg" alt=""></div>
                <div class="title-input mx-1">حقل ارقام</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input"  @click="addShortText('email')">
                <div class="icon-input"><img src="@/assets/form/email.svg" alt=""></div>
                <div class="title-input mx-1">حقل ايميل</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input"  @click="addShortText('textarea')">
                <div class="icon-input"><img src="@/assets/form/text.svg" alt=""></div>
                <div class="title-input mx-1">صندوق نصي</div>
            </div>
        </div>
         <div class="col-6">
          <div class="bg-light d-flex  shadow-sm p-2" @click="addShortText('radio')">
                <div class="icon-input"><img src="@/assets/form/yesno.svg" alt=""></div>
                <div class="title-input mx-1">حقل  نعم او لا</div>
            </div>
        </div>
          <div class="col-6">
            <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('select')">
                <div class="icon-input"><img src="@/assets/form/dropdown.svg" alt=""></div>
                <div class="title-input mx-1">قائمة منسدلة</div>
            </div>
        </div>
        <div class="col-6">
            <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('checkbox')">
                <div class="icon-input"><img src="@/assets/form/redio.svg" alt=""></div>
                <div class="title-input mx-1">متعدد الاختيار</div>
            </div>
        </div>
         <div class="col-6">
            <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('radio')">
                <div class="icon-input"><img src="@/assets/form/radio.svg" alt=""></div>
                <div class="title-input mx-1">خيار واحد</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('color')">
                <div class="icon-input"><img src="@/assets/form/color.svg" alt=""></div>
                <div class="title-input mx-1">حقل اختيار لون</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('date')">
                <div class="icon-input"><img src="@/assets/form/date.svg" alt=""></div>
                <div class="title-input mx-1">تاريخ</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('time')">
                <div class="icon-input"><img src="@/assets/form/clock.svg" alt=""></div>
                <div class="title-input mx-1">وقت</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('password')">
                <div class="icon-input"><img src="@/assets/form/pass.svg" alt=""></div>
                <div class="title-input mx-1">كلمة مرور</div>
            </div>
        </div>
         <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('url')">
                <div class="icon-input"><img src="@/assets/form/link.svg" alt=""></div>
                <div class="title-input mx-1">رابط</div>
            </div>
        </div>
         <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('file')">
                <div class="icon-input"><img src="@/assets/form/file.svg" alt=""></div>
                <div class="title-input mx-1">رفع ملف</div>
            </div>
        </div>
        <div class="col-6">
          <div class="bg-light d-flex align-items-center  shadow-sm p-2 item-add-input" @click="addShortText('tel')">
                <div class="icon-input"><img src="@/assets/form/tel.svg" alt=""></div>
                <div class="title-input mx-1">تلفون</div>
            </div>
        </div>
      </div>
      
    </div>
    <!-- end body -->
  </div>
  <div class="tab-pane p-3 fade " id="setting" role="tabpanel" aria-labelledby="setting-tab">
    <div class="title-tab-custom">الاعدادات</div>
    <div class="text-helper">قائمة الإعدادات لنظام مسار العمل</div>
    <div class="content-tab-custom bg-light mt-3 p-2">
      <div class="row gy-3 py-3">
        
        <div class="col-12">
           <div class="form-group">
                <label for="formName">عنوان مسار العمل</label>
                <input type="text" class="form-control" id="formName" v-model="form.general_data.title"  placeholder="ادخل عنوان مسار العمل">
            </div>
        </div>
        <div class="col-12">
          <div class="form-group">
                <label for="Description">وصف قصير</label>
                
                <textarea class="form-control" id="Description"  rows="3" v-model="form.general_data.short_text"></textarea>
            </div>
        </div>
        <div class="col-12">
              <div class="form-group">
                <label for="Status">الحالة</label>
                <select class="form-control" id="Status" v-model="form.general_data.status">
                    <option selected value="1">تفعيل</option>
                    <option value="0">تعطيل</option>
                </select>
            </div>
        </div>
        <div class="col-12">
          <div class="form-group">
                <label for="Language">اللغة</label>
                <select class="form-control" id="Language" v-model="form.general_data.lang">
                  <option selected value="rtl">العربية</option>
                    <option value="ltr">English</option>
                </select>
            </div>
        </div>
        <div class="col-12">
          <div class="form-group">
                            <label>كلمة مرور الحماية</label>
                            <div class="d-flex password-protection mb-2">

                                <label class="switch">
                                    <input type="checkbox" v-model="form.general_data.password_protection.status">
                                    <span class="slider round"></span>
                                </label>

                                <span class="me-2" v-if="form.general_data.password_protection.status == true">كلمة المرور مفعلة</span>
                                <span class="me-2" v-else> تفعيل كلمة مرور الحماية</span>
                            </div>
                            <div v-if="form.general_data.password_protection.status == true">
                                <input type="text" v-model="form.general_data.password_protection.password" class="form-control password-protection-input" placeholder="ادخل كلمة المرور">
                            </div>
                        </div>
        </div>

      </div>
           
            
    </div>
    <!-- end body -->
  </div>
  <div class="tab-pane p-3 fade " id="style_form" role="tabpanel" aria-labelledby="style_form-tab">
    <div class="title-tab-custom">الستايل</div>
    <div class="text-helper">اختر الستايل الذي تراه مناسب</div>
    <div class="content-tab-custom select-stayle">
      
      <div class="radio-with-Icon">
  <p class="radioOption-Item">
    <input type="radio" name="BannerTypes" id="BannerType1" value="style1"  v-model="form.general_data.style" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
    <label for="BannerType1">
      <img src="@/assets/form/styles/tem1.svg" width="100%" alt="">
      
    </label>
  </p>

  <p class="radioOption-Item">
    <input type="radio" name="BannerTypes" id="BannerType2" value="style2" v-model="form.general_data.style" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
    <label for="BannerType2">
     <img src="@/assets/form/styles/tem2.svg" width="100%" alt="">
    </label>
  </p>

  <p class="radioOption-Item">
    <input type="radio" name="BannerTypes" id="BannerType3" value="style3" v-model="form.general_data.style" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
    <label for="BannerType3">
     <img src="@/assets/form/styles/tem3.svg" width="100%" alt="">
    </label>
  </p>


  <p class="radioOption-Item">
    <input type="radio" name="BannerTypes" id="BannerType4" value="style4" v-model="form.general_data.style" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
    <label for="BannerType4">
     <img src="@/assets/form/styles/tem4.svg" width="100%" alt="">
    </label>
  </p>


</div>
    </div>
    <!-- end body -->
  </div>
  <div class="tab-pane p-3 fade  " id="template_form" role="tabpanel" aria-labelledby="template_form-tab">
    <div class="title-tab-custom">القوالب</div>
    <div class="text-helper">يمكنك اختيار قالب تم حفظة سابقا</div>
    <div class="content-tab-custom">
      <div class="content-tab-custom select-stayle">
      
      <div class="radio-with-Icon">
        <div class="row gx-3">
          <div class="col-6">
            <p class="radioOption-Item p-1 m-0 w-100 h-auto" >
              <input @change="editTemplate('temp1')" type="radio" name="templateTypes" id="templateType1"  value="0" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
              <label   for="templateType1" class="h-auto text-center p-2">
                <div ><img src="@/assets/form/temp/newTem1.svg" width="100%" height="125" alt=""></div>
                <div class=" text-center">عنوان التمبليت</div>
              </label>
            </p>
          </div>
    <div class="col-6">
            <p class="radioOption-Item p-1 m-0 w-100 h-auto">
              <input @change="editTemplate('temp2')" type="radio" name="templateTypes" id="templateType2" value="1" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
              <label for="templateType2" class="h-auto text-center p-2">
                <div><img src="@/assets/form/temp/newTem2.svg" width="100%"  height="125"  alt=""></div>
                <div class=" text-center">عنوان التمبليت</div>
              </label>
            </p>
          </div>
             <div class="col-6">
            <p class="radioOption-Item p-1 m-0 w-100 h-auto">
              <input @change="editTemplate(2)" type="radio" name="templateTypes" id="templateType3" value="2" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
              <label for="templateType3" class="h-auto text-center p-2">
                <div><img src="@/assets/form/temp/newTem3.svg" width="100%"  height="125"  alt=""></div>
                <div class=" text-center">عنوان التمبليت</div>
              </label>
            </p>
          </div>
             <div class="col-6">
            <p class="radioOption-Item p-1 m-0 w-100 h-auto">
              <input  @change="editTemplate(3)" type="radio" name="templateTypes" id="templateType4" value="3" class="ng-valid ng-dirty ng-touched ng-empty" aria-invalid="false" style="">
              <label for="templateType4" class="h-auto text-center p-2">
                <div><img src="@/assets/form/temp/newTem4.svg" width="100%"  height="125"  alt=""></div>
                <div class=" text-center">عنوان التمبليت</div>
              </label>
            </p>
          </div>
        </div>




</div>
    </div>
    </div>
    <!-- end body -->
  </div>
</div>

            </div>
        </div>
        <!-- slide-left -->
       <div class="row align-items-center justify-content-between pb-3 mt-4 mt-lg-0">
                <div class="col-auto">
                    <h1 class="title-page">انشاء نموذج</h1>
                </div>
                <div class="col-auto">
                    
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div class="form-code small">Version: <b>{{form.general_data.version}}</b></div>
                <div class="form-code small">Code: <b>{{form.general_data.code}}</b></div>
            </div>
                <div class="card py-4 px-4 mb-3">
                    
               <div class="    mb-3 ">
                        <label> عنوان النموذج *</label>
                        <input type="text"  class="form-control " v-model="form.general_data.title"  >
                    </div>
                   
                </div>
            <div class="card py-4 px-4 mb-3 " id="card-cutome-lang" :dir="form.general_data.lang">
                    <!-- ساحة العمل -->
                          <div class="body-form mb-4">
            
                    <div class="row g-4" v-if="form.fields[0]" :id="form.general_data.style">
                     <draggable class="dragArea row g-4" :list="form.fields" @change="log">
                      <div :class="field.lg +' '+ field.sm" v-for="(field , index) in form.fields" :key="field" >
                     

                        <div class="row">
                          
                        <div class="col position-relative box-item-input">
                          
                         <div class="item-input" v-if="field.have_options == 'no' ">
                         
                              <div>
                                <label for="" class="form-label">{{field.title}}</label>
                                <textarea v-if="field.type == 'textarea'"  class="form-control" id="" cols="30" rows="3" :placeholder="field.placeholder"></textarea>
                              <input v-else :type="field.type"  class="form-control" :placeholder="field.placeholder" id="">
                              
                            </div>
                            <div>{{field.text_under}}</div>
                         </div>
                         <div class="item-input" v-if="field.have_options =='yes'">
                          <input type="hidden" class="minlength_value" value="">
                          <input type="hidden" class="maxlength_value" value="">
                              <div>
                                <label for="" class="form-label">{{field.title}}</label>
                                <div v-if="field.type == 'checkbox' || field.type == 'radio'">
                                    <div class="form-check form-check-inline" v-for="(option , i) in field.options.options_all" :index="i" :key="option">
                                     <input class="form-check-input" :type="field.type" :id="field.options.option_name+'_'+i" :name="field.options.option_name" :value="option.value">
                                    <label class="form-check-label" :for="field.options.option_name+'_'+i">{{option.label}}</label>
                                     
                                    </div>
                                </div>
                                <div v-if="field.type == 'select'">
                                  <select class="form-select" >
                                    <option selected>اختر من القائمة</option>
                                    <option v-for="(option , i) in field.options.options_all" :index="i" :key="option" :id="field.options.option_name+'_'+i" :name="field.options.option_name" :value="option.value">{{option.label}}</option>
                                  </select>
                                 
                                </div>

                            </div>
                            <div>{{field.text_under}}</div>
                         </div>
                         <div class="btn-options ">
                          <button class="edit-btn" data-bs-toggle="modal" data-bs-target="#exampleModal" v-on:click="editEntry(field,index)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.487" viewBox="0 0 15.752 15.487">
                                            <g id="Group_11717" data-name="Group 11717" transform="translate(-262.073 -308.883)">
                                              <g id="Group_11433" data-name="Group 11433" transform="translate(262.073 309.831)">
                                                <path id="Path_14054" data-name="Path 14054" d="M12.243,44.213a.764.764,0,0,0-.765.765v2.4a.765.765,0,0,1-.765.765H2.3a.765.765,0,0,1-.765-.765V37.434a.765.765,0,0,1,.765-.765H6.741a.765.765,0,0,0,0-1.53H2.3a2.3,2.3,0,0,0-2.3,2.3v9.948a2.3,2.3,0,0,0,2.3,2.3h8.417a2.3,2.3,0,0,0,2.3-2.3v-2.4A.764.764,0,0,0,12.243,44.213Z" transform="translate(0 -35.138)" fill="#929292"></path>
                                              </g>
                                              <g id="Group_11436" data-name="Group 11436" transform="translate(266.481 308.883)">
                                                <g id="Group_11435" data-name="Group 11435" transform="translate(0 0)">
                                                  <path id="Path_14055" data-name="Path 14055" d="M153.754,5.147a2.945,2.945,0,0,0-4.136-.007l-5.18,5.18a3.993,3.993,0,0,0-1.172,2.836v1.714a.765.765,0,0,0,.765.765h1.714a3.994,3.994,0,0,0,2.837-1.173l5.174-5.174A2.935,2.935,0,0,0,153.754,5.147ZM147.5,13.38a2.473,2.473,0,0,1-1.755.725H144.8v-.949a2.472,2.472,0,0,1,.725-1.755l3.415-3.415,1.979,1.979Zm5.172-5.172L152,8.884,150.017,6.9l.676-.676a1.4,1.4,0,0,1,1.979,1.979Z" transform="translate(-143.265 -4.296)" fill="#929292"></path>
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                            </button>
                           <button class=" delete-btn"  v-on:click="removeEntry(field)">
                           <svg xmlns="http://www.w3.org/2000/svg" width="14.473" height="17.819" viewBox="0 0 14.473 17.819">
                                              <g id="Group_11718" data-name="Group 11718" transform="translate(-180.775 -308.09)">
                                                <path id="Path_14100" data-name="Path 14100" d="M222.815,154.7a.417.417,0,0,0-.417.417v7.887a.417.417,0,1,0,.835,0V155.12A.417.417,0,0,0,222.815,154.7Zm0,0" transform="translate(-32.342 159.844)" fill="#929292"></path>
                                                <path id="Path_14101" data-name="Path 14101" d="M104.815,154.7a.417.417,0,0,0-.417.417v7.887a.417.417,0,0,0,.835,0V155.12A.417.417,0,0,0,104.815,154.7Zm0,0" transform="translate(80.734 159.844)" fill="#929292"></path>
                                                <path id="Path_14102" data-name="Path 14102" d="M1.182,5.3V15.585a2.3,2.3,0,0,0,.612,1.588,2.055,2.055,0,0,0,1.491.645h7.9a2.055,2.055,0,0,0,1.491-.645,2.3,2.3,0,0,0,.612-1.588V5.3a1.594,1.594,0,0,0-.409-3.135H10.738V1.647A1.639,1.639,0,0,0,9.086,0H5.38A1.639,1.639,0,0,0,3.728,1.647v.522H1.591A1.594,1.594,0,0,0,1.182,5.3Zm10,11.68h-7.9a1.322,1.322,0,0,1-1.269-1.4V5.34H12.449V15.585a1.322,1.322,0,0,1-1.269,1.4ZM4.562,1.647A.8.8,0,0,1,5.38.833H9.086a.8.8,0,0,1,.818.814v.522H4.562ZM1.591,3H12.875a.751.751,0,0,1,0,1.5H1.591a.751.751,0,1,1,0-1.5Zm0,0" transform="translate(180.778 308.092)" fill="#929292"></path>
                                                <path id="Path_14103" data-name="Path 14103" d="M163.815,154.7a.417.417,0,0,0-.417.417v7.887a.417.417,0,0,0,.835,0V155.12A.417.417,0,0,0,163.815,154.7Zm0,0" transform="translate(24.196 159.844)" fill="#929292"></path>
                                              </g>
                                            </svg>
                            </button>
                        </div>

                        </div>
                        </div>
                      
                      </div>
</draggable>



                    </div>
                </div>

                <div class="body-form " >
                    <span class="text-center" @click="toggle = !toggle">انقر هنا لإضافة حقل جديد</span>
             
                </div>
                   <!--نهاية ساحة العمل   -->

                   <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title d-flex" id="exampleModalLabel">اعدادات الحقل <div class=" fs-16-i text-success mx-2">" {{this.events.title}} "</div></h5>
        <div>
          <span v-if="this.events.required == 'true'">مطلوب</span>
          <span v-else>غير مطلوب</span>
          <label class="switch">
          <input type="checkbox" :checked=" !this.events.required == false" value="true" class="required_value">
          <span class="slider round"></span>
          </label> </div>

        <!-- <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <div class="modal-body">
        <div class="row g-3">
           <div class="col-9">
              <div class="">
                <label  class="form-label">عنوان الحقل</label>
                <input type="text" class="form-control title_value"   v-model="this.events.title" >
                </div>
          </div>
      <div class="col-3">
              <div class="">
                <label  class="form-label">نوع الحقل</label>
                <input type="text" class="type_value form-control" readonly :value="this.events.type">
                <input type="hidden" class="name_value" :value="this.events.name">
                
             
             <input v-if="this.events.type == 'checkbox'" type="hidden" class="value_value" :value="['']">
             <input v-else type="hidden" class="value_value" :value="this.events.value">
                </div>
          </div>
         
          <div class="col-6">
              <div class="">
                <label  class="form-label">النص المساعد</label>
                <input type="text" class="form-control placeholder_value"  :value="this.events.placeholder" >
                
                </div>
          </div>
          <div class="col-6">
              <div class="">
                <label  class="form-label">النص ارشادي اسفل الحقل</label>
                <input type="text" class="form-control text_under_value"  :value="this.events.text_under" >
                </div>
          </div>
          <div class="col-12"><hr></div>
          <div class="col-12" >
            <div class="row" v-if="this.events.have_options =='no'">
                  <div class="col-6">
                  <div class="">
                    <label  class="form-label">اقل عدد للحروف</label>
                    <input type="text" class="form-control minlength_value"  :value="this.events.minlength" >
                    </div>
              </div>
              <div class="col-6">
                  <div class="">
                    <label  class="form-label">اقصى عدد للحروف</label>
                    <input type="text" class="form-control maxlength_value"  :value="this.events.maxlength" >
                    </div>
              </div>
            </div>
            <div class="row" v-show="this.events.have_options =='yes'">
            <input type="hidden" class="have_options_value" :value="this.events.have_options">
              <div class="col-12">
                <div class="row align-items-center justify-content-between gx-1 mb-3"  v-for="option in this.events.options.options_all" :key="option">
                  <div class="col ">
                    <div class="row gx-1 gy-3">
                      <div class="col-6">
                    <input type="text" class=" form-control" placeholder="العنوان" v-model="option.label">
                  </div>
                  <div class="col-6">
                    <input type="text" class=" form-control" placeholder="القيمة" v-model="option.value">
                  </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-danger p-1" @click="removeOption(option)">حذف</button>
                  </div>
                </div>
                <div class="mt-3"><button class=" btn btn-success add_new_option" @click.prevent="addOption()">اضافة عنصر جديد</button></div>
              </div>
            </div>
          </div>
          <div class="col-12"><hr></div>
            <div class="col-6">
              <div class="">
                <label  class="form-label">Class</label>
                <input type="text" class="form-control class_value"  :value="this.events.class" >
                </div>
          </div>
            <div class="col-6">
              <div class="">
                <label  class="form-label">ID</label>
                <input type="text" class="form-control id_value"  :value="this.events.id" >
                </div>
          </div>
          <div class="col-12"><hr></div>
           <div class="col-6">
              <div class="">
                <label  class="form-label">عرض الحقل على الشاشة الكبيرة</label>
                  <div>
                    <select name="" class="form-control lg_value" id="" :value="this.events.lg" >
                      <option value="col-lg-12">كامل العرض</option>
                      <option value="col-lg-6">نصف العرض</option>
                      <option value="col-lg-3">ربع العرض</option>
                    </select>
                  </div>
                </div>
          </div>
            <div class="col-6">
              <div class="">
                <label  class="form-label">عرض الحقل على الشاشة الصغيرة</label>
               <div>
                    <select name="" class="form-control sm_value" id="" :value="this.events.sm">
                      <option value="col-12">كامل العرض</option>
                      <option value="col-6">نصف العرض</option>
                      <option value="col-4">ربع العرض</option>
                    </select>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">خروج</button>
        <button type="button" class="btn btn-primary"  data-bs-dismiss="modal"  @click="updateEntry(this.events.number)">حفظ</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
                </div>
         <div class="d-flex align-items-center justify-content-center">
          <button @click="changeStatusStep('[1]')" class="btn btn-success mx-1 "  >التالي</button>
         </div>

     


  </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ /* options */ });

import { defineComponent } from 'vue';
 import { VueDraggableNext } from 'vue-draggable-next'
export default defineComponent( {
  name: "FormComponent",
  components: {
      draggable: VueDraggableNext,
    },
      props:["FormHome"],
  data: function(){
      return {
        form: this.FormHome ,
        
            

                    temp : 
                  {
                    temp1:[
                      {
                        title: "عنوان الحقل",
            name: "123123",
            required: false, 
            placeholder: null,
            type: "text",
            text_under: null,
            minlength:null,
            maxlength:null,
            class:null,
            id:null,
            lg:"col-lg-6",
            sm:"col-12",
            have_options: "no",
            options:{
              have_options : "no",
              option_name : "123123",
              options_all :[
                {
                  label: "العنوان الاول",
                  value: "1",
                  selected: "selected"
                },
                {
                  label: "العنوان الثاني",
                  value: "2",
                  selected: ""
                }
              ]
          },
                      },
                       {
                        title: "عنوان الحقل",
            name: "123123",
            required: false, 
            placeholder: null,
            type: "text",
            text_under: null,
            minlength:null,
            maxlength:null,
            class:null,
            id:null,
            lg:"col-lg-6",
            sm:"col-12",
            have_options: "no",
            options:{
              have_options : "no",
              option_name : "123123",
              options_all :[
                {
                  label: "العنوان الاول",
                  value: "1",
                  selected: "selected"
                },
                {
                  label: "العنوان الثاني",
                  value: "2",
                  selected: ""
                }
              ]
          },
                      }
                    ],
                     temp2:[
                      {
                        title: "عنوان عنوان حل اخر",
            name: "123123",
            required: false, 
            placeholder: null,
            type: "text",
            text_under: null,
            minlength:null,
            maxlength:null,
            class:null,
            id:null,
            lg:"col-lg-12",
            sm:"col-12",
            have_options: "no",
            options:{
              have_options : "no",
              option_name : "123123",
              options_all :[
                {
                  label: "العنوان الاول",
                  value: "1",
                  selected: "selected"
                },
                {
                  label: "العنوان الثاني",
                  value: "2",
                  selected: ""
                }
              ]
          },
                      },
                       {
                        title: "عنوان الحقل",
            name: "123123",
            required: false, 
            placeholder: null,
            type: "text",
            text_under: null,
            minlength:null,
            maxlength:null,
            class:null,
            id:null,
            lg:"col-lg-12",
            sm:"col-12",
            have_options: "no",
            options:{
              have_options : "no",
              option_name : "123123",
              options_all :[
                {
                  label: "العنوان الاول",
                  value: "1",
                  selected: "selected"
                },
                {
                  label: "العنوان الثاني",
                  value: "2",
                  selected: ""
                }
              ]
          },
                      }
                    ], 
                    
                  }
                     ,
        enabled: true,
        dragging: false,
         toggle: false,
         filed: {
          required: false, 
          title: "حقل نصي",
          placeholder: null,
          type: "text",
          name:null,
          text_under:null,
          minlength:null,
          maxlength:null,
          class:null,
          id:null,
          lg:"col-lg-12",
          sm:"col-12",
          
          },
          titlef : "", // عنوان الحقل يتغير حسب اختيار نوع الحقل
          value:null,
          random:null,
          have_options:"no",
          options:{
            have_options : "no",
            options_all :[
              {
                label: "العنوان الاول",
                value: "1",
                selected: "selected"
              },
              {
                label: "العنوان الثاني",
                value: "2",
                selected: ""
              }
            ]
          },
          events: {
            required: false, 
            number: null ,
            name: null ,
            value: null ,
            title: null,
            placeholder: null,
            type: null,
            text_under: null,
            minlength:null,
            maxlength:null,
            class:null,
            id:null,
            lg:"col-lg-12",
            sm:"col-6",
            have_options : "no",
            options:{
            options_all :[
              {
                label: "العنوان الاول",
                value: "1",
                selected: "selected"
              },
              {
                label: "العنوان الثاني",
                value: "2",
                selected: ""
              }
            ]
          },
            
          },
         
      }
  },

     methods: {
      changeStatusStep(statuss) {
        if(this.form.fields[0] != null){
          
this.$emit('changeStatusStep' , statuss)
       toaster.success(`تم تخزين النموذج بنجاح`);
        }
        else{
           toaster.warning(`يتطلب منك ادخال حقول لهذا النموذج`);
        }
       
      },
       log(event) {
        console.log(event)
      },
     
       addOption() {
          this.events.options.options_all.push({
           
                label: "",
                value: "",
                selected: ""
              
          });
       },
    editTemplate(){
      this.form.fields.splice(0);
      this.form.fields.push(...this.temp.temp1);
    },
    addShortText(typee) {
          this.random = 'input'+Math.floor(Math.random() * 10000) ;
        
           if(typee == "checkbox" || typee == "select" || typee == "radio"){
             this.titlef = "حقل متعدد الاختيار",
             this.have_options = "yes",
            this.value = []
          }
          else{
            this.titlef = "عنوان الحقل",
             this.have_options = "no",
             this.value = ""
          }
          this.form.fields.push({
            title: this.titlef,
            value: this.value,
            name: this.random,
            required: false, 
            placeholder: null,
            type: typee,
             text_under: null,
            minlength:null,
            maxlength:null,
            class:null,
            id:null,
            lg:"col-lg-12",
            sm:"col-12",
            have_options: this.have_options,
            options:{
              have_options : this.have_options,
              option_name : this.random,
              options_all :[
                {
                  label: "العنوان الاول",
                  value: "1",
                  selected: "selected"
                },
                {
                  label: "العنوان الثاني",
                  value: "2",
                  selected: ""
                }
              ]
          },
          });
      
      
    },
     editEntry:function(filed,index) {
            this.events = filed;
            this.events.number = index;

        },
         removeOption:function(option) {
        //   this.$delete(this.queue, index);
            this.events.options.options_all.splice(this.events.options.options_all.indexOf(option), 1);
        },
         removeEntry:function(filed) {
        //   this.$delete(this.queue, index);
            this.form.fields.splice(this.form.fields.indexOf(filed), 1);
        },
     updateEntry:function(index) {
             this.form.fields[index] = {
            'required': document.querySelector('.required_value').value,
            'type': document.querySelector('.type_value').value,
            'name': document.querySelector('.name_value').value,
            'value': document.querySelector('.value_value').value,
            'placeholder': document.querySelector('input.placeholder_value').value,
            'title': document.querySelector('input.title_value').value,
            'text_under': document.querySelector('input.text_under_value').value,
            'minlength': document.querySelector('input.minlength_value').value,
            'maxlength': document.querySelector('input.maxlength_value').value,
            'class': document.querySelector('input.class_value').value,
            'id': document.querySelector('input.id_value').value,
            'lg': document.querySelector('.lg_value').value,
            'sm': document.querySelector('.sm_value').value,
            'have_options': document.querySelector('.have_options_value').value,
            'options': this.events.options,
            
        };
      //  this.events.quantity = '';
        },
    }

});
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
$white : #fff;
// $light : var(--light);

$blue: #546BCC;
$blueDark: #212090;
$light: #F1F4F5;
$success: #41BCD0;
$pink: #624491;
$black: #b39d9d;
$pinkWhite: #F4BAC2;
$warning:#FCB131;
$shadow: 0 0 10px rgba(0, 0, 0, 0.20);
    .body-form{
        
 span {
        display: block;
        border: 1px dashed $success;
        padding: 40px;
        cursor: pointer;
        text-align: center;
        color: $success;
    }
    }
      // form-bilder
.item-add-input{
  &:hover{
    cursor: pointer;
  }
}
</style>