<template>
    <div class="publish-com">
          <div class="row align-items-center justify-content-between pb-3  mt-lg-0">
                <div class="col-auto">
                    <h1 class="title-page">النشر</h1>
                </div>
                <div class="col-auto">
                    
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div class="form-code small">Version: <b>{{form.general_data.version}}</b></div>
                <div class="form-code small">Code: <b>{{form.general_data.code}}</b></div>
            </div>
            <div class="card py-4 px-4 mb-3">
                <div>عنوان النموذج</div>
                <div class="fs-20 text-primary mt-2 pb-3 mb-3 border-bottom border-1">{{form.general_data.title}}</div>

                <div>مستخدمي النموذج</div>
                <div class="fs-20 text-primary mt-2 pb-3 mb-3 border-bottom border-1">{{form.workflow.general_data.start_all == 'allUsers' ? 'كل المستخدمين':'' || form.workflow.general_data.start_all == 'departmentUsers' ? 'اقسام محددين':'' || form.workflow.general_data.start_all == 'customUsers' ? 'مستخدمين محددين':''}}</div>

                <div>{{form.publish_form.send_notifications_by.title}}</div>
                <div class=" text-primary mt-2 pb-3 mb-3 border-bottom border-1">
                    
    <div class="form-check form-check-inline" v-for="(choice , i)  in form.publish_form.send_notifications_by.options"  :index="i" :key="choice">
    <input class="form-check-input" type="checkbox" :name="'choice_publish_'+i" :id="'choice'+i+'_publish_'+i" :value="choice.value" v-model="form.publish_form.send_notifications_by.selected">
    <label class="form-check-label" :for="'choice'+i+'_publish_'+i">{{choice.label}}</label>
    </div>
                </div>

            </div>
                <div class="d-flex align-items-center justify-content-center">
                      <button @click="changeStatusStep('[1,2]')" class="btn btn-primary mx-1">السابق</button>
          <button @click="submit()" class="btn btn-success mx-1">إرسال</button>
         </div>
    </div>
</template>

<script>
export default {
    name: "ThePublish",
     props:["FormHome"],
    data: function(){
        return{
            form : this.FormHome,
        }
    },
    methods:{
          changeStatusStep(statuss) {

this.$emit('changeStatusStep' , statuss)

    },
    submit(){
        
    }
    }
}
</script>