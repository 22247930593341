<template>
   
        <footer  class="footer-component mt-auto">
            <div class=" container text-center py-3">
                جميع الحقوق محفوظة لدى MTC
            </div>
        </footer>
   
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>