import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/main-ar.scss";
import { Tooltip } from "bootstrap";
import Toaster from "@meforma/vue-toaster";

export default {
    mounted() {
       //inti tooltip
       Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
   .forEach(tooltipNode => new Tooltip(tooltipNode))
  }
  }
createApp(App).use(store).use(Toaster).use(router).mount("#app");
