<template>
    <div class="work-flow">
          <div class="row align-items-center justify-content-between pb-3 mt-4 mt-lg-0">
                <div class="col-auto">
                    <h1 class="title-page">مسار العمل لـ "<span class="fs-20 text-primary">{{form.general_data.title}}</span>"</h1>
                </div>
                <div class="col-auto">
                    
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div class="form-code small">Version: <b>{{form.general_data.version}}</b></div>
                <div class="form-code small">Code: <b>{{form.general_data.code}}</b></div>
            </div>
              <div class="card py-4 px-4 mb-3">
                  <div class="row  justify-content-center">
                      <div class="col-lg-8">
                           <ul class="work-flow-steps my-4">
                    <li class="start"></li>
        
                    <li class="who-can-start">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-9">
                             
                
                                <ul class="card w-100 open border-success ">
                
                                    <div class="question mt-0 pt-0 fs-18-i text-primary">مستخدمي هذا النموذج:</div>
                                    <div class="bg-light p-3 mb-0">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" v-model="form.workflow.general_data.start_all" name="usersForm" value="allUsers" id="allUsers">
                                            <label class="form-check-label" for="allUsers">
                                                جميع المستخدمين
                                            </label>
                                            </div>
                                             <div class="form-check">
                                            <input class="form-check-input" type="radio" v-model="form.workflow.general_data.start_all" name="usersForm" value="departmentUsers" id="departmentUsers">

                                            
                                            <label class="form-check-label" for="departmentUsers">
                                                اقسام محددين
                                            </label>
                                            </div>
                                            <div class="form-check">
                                            <input class="form-check-input" type="radio" v-model="form.workflow.general_data.start_all" name="usersForm" value="customUsers" id="customUsers">
                                            <label class="form-check-label" for="customUsers">
                                                مستخدمين محددين
                                            </label>
                                            </div>
                                            <div>
                                                <div  v-if="form.workflow.general_data.start_all =='departmentUsers'">
                                                <VueMultiselect 
                                                v-model="form.workflow.general_data.start_department.department_ids"
                                                :options="departments"
                                                :multiple="true"
                                                :close-on-select="true"
                                                placeholder="اختر الاقسام"
                                                label="department"
                                                track-by="department" />
                                                </div>
                                           
                                             <div v-if="form.workflow.general_data.start_all =='customUsers'">
                                                <VueMultiselect  
                                                v-model="form.workflow.general_data.start_this_form"
                                                :options="options"
                                                :multiple="true"
                                                :close-on-select="true"
                                                placeholder="اختر المستخدمين"
                                                label="name"
                                                track-by="name" />
                                             </div>
                                               
                                            </div>
                                    </div>
                                    <div class="mt-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" v-model="form.workflow.general_data.have_end_date" name="date_end" type="radio" checked id="deadline_0" value="0">
                                            <label class="form-check-label" for="deadline_0">مدة استخدام غير محدودة</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" v-model="form.workflow.general_data.have_end_date" name="date_end" type="radio" id="deadline_1" value="1">
                                            <label class="form-check-label" for="deadline_1">مدة استخدام محدودة</label>
                                            </div>
                                            <div>
                                                <input v-if="form.workflow.general_data.have_end_date == '1'"  type="datetime-local" id="datetody" v-model="form.workflow.general_data.end_date" class=" form-control" placeholder="حدد تاريخ الانتهاء">
                                             </div>

                                    </div>
                                </ul>
                            </div>
                        </div>
                        

                    </li>
                    
                    <li  class="li1 active-li">
                        <ul class="list-steps">
                            <draggable class="dragArea row g-4" :list="form.workflow.steps" @change="log">
                            <li :class="step.haveEmpty == 'yes' ? 'error' : '' " class="li1 active-li" v-for="(step , index) in form.workflow.steps" :index="index" :key="step.step_number">
                                <div class="setting">
                                    <a href="#" class="plus  bg-primary d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#fff" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg>
                                    </a>
                                </div>
                                <div class="row align-items-center justify-content-center">
                            <div class="col-lg-9">
                                <ul class="card w-100 card-add-task">
                                    <span class="close-card" @click="removeStep(step)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                    </span>
                                    <div class="question mt-0 pt-0 fs-18-i text-primary">
                                        <label for="basic-url" class="form-label"></label>
                                        <div class="input-group mb-3">
                                        <span class="input-group-text bg-transparent border-0" id="basic-addon3">
                                            <svg v-if="step.type == 'approval'" xmlns="http://www.w3.org/2000/svg" fill="#41BCD0" width="32" height="32" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  width="20" height="20"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#546BCC" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"/></svg>                                            

                                            </span>
                                        <input type="text" class="form-control bg-transparent fs-18-i border-1 border-light" v-model="step.title" placeholder="ادخل عنوان المهمة" id="basic-url" aria-describedby="basic-addon3">
                                        </div>
                                    </div>
                                    <div class="bg-light p-3">
                                        <div class=" mb-0 ">
                                             <div class="mb-3 text-bold mt-1 text-success" v-if="step.type == 'approval'">من يستطيع الموافقة</div>
                                             <div class="mb-3 text-bold mt-1 text-primary" v-else>من يستطيع كتابة ملاحظة</div>
                                           <div class="form-check">
                                            <input class="form-check-input" type="radio" :name="'usersForm'+index" value="department" :id="'department'+index" v-model="step.assign_to">
                                            <label class="form-check-label" :for="'department'+index">
                                               اقسام محددين
                                            </label>
                                            </div>
                                             <div class="form-check">
                                            <input class="form-check-input" type="radio" :name="'usersForm'+index" value="users" :id="'users'+index" v-model="step.assign_to">
                                            <label class="form-check-label" :for="'users'+index">
                                                مستخدمين محددين
                                            </label>
                                            </div>
                                            <div>
                                            <VueMultiselect v-if="step.assign_to == 'users'"
                                                v-model="step.emails"
                                                :options="options"
                                                :multiple="true"
                                                :close-on-select="true"
                                                placeholder="اختر المستخدم"
                                                label="name"
                                                track-by="name" />

                                                <VueMultiselect v-if="step.assign_to == 'department'"
                                                v-model="step.start_department.department_ids"
                                                :options="departments"
                                                :multiple="true"
                                                :close-on-select="true"
                                                placeholder="اختر الاقسام"
                                                label="department"
                                                track-by="department" />
                                            </div>
                                        </div>
                
                                    </div>
                
                                </ul>
                            </div>
                                </div>  
                            </li>
                            </draggable>
                        </ul>
                    </li>
                    <li class="plus-add " >
                        <div class="setting link-add-workflow disabled">
                            <span class="add-approval" @click="addStep('approval' ,lengthstep)" data-bs-toggle="tooltip" data-bs-placement="top" title="اضافة موافقة">
                                <svg  width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"/></svg>
                            </span>
                            <a href="#" class="plus d-flex align-items-center justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#fff" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/></svg>                           
                            </a>
                             <span class="add-note"  @click="addStep('note' , lengthstep)" data-bs-toggle="tooltip" data-bs-placement="top" title="اضافة ملاحظة">
                                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"/></svg>
                            </span>
                        </div>
                    </li>
                    <li class="end"></li>
                    <li class="end-text text-center">النهاية</li>
                </ul>
                      </div>
                  </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
           <button @click="changeStatusStep('[]')" class="btn btn-primary mx-1">السابق</button>
          <button @click="changeStatusStep('[1,2]')" class="btn btn-success mx-1" :class=" form.workflow.start_all == '' ? 'disabled' : ''  || form.workflow.steps[0] == null ? 'disabled' : '' ">التالي</button>
         </div>
    </div>
</template>

<script >
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ /* options */ });
import VueMultiselect from "vue-multiselect";
import { Tooltip } from "bootstrap";
import { defineComponent } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next'
export default defineComponent({
    name:"TheWorkFlow",
      components: {
      draggable: VueDraggableNext,
     VueMultiselect 
    },
     props:["FormHome"],
    data (){
        return {
            enabled: true,
            dragging: false,
            form : this.FormHome,
            typeStep:null,
            lengthstep:0,
            optionsStep:null,
            titleStep:null,
            value: [],
      options: [
        { name: 'صالح حبوش', id :'1'},
        { name: 'Osama almadhon', id :'1' },
        { name: 'Mohmmed Khaled', email: 'mohmmed@mtc.ps' , id :'1'},
        { name: 'Ahmed Saed', email: 'ahmed@mtc.ps' , id :'1'},
        { name: 'Tareq dokhan', email: 'tareq@mtc.ps', id :'1' },
        { name: 'Rasim moshtaha', email: 'rasim@mtc.ps' , id :'1'}
      ],
      departments: [
        { department: 'قسم المبيعات', id :'1' },
        { department: 'قسم المشتريات', id :'2' },
        { department: 'قسم الادارة المالية', id :'3' },
        { department: 'قسم العمليات', id :'4' },
        { department: 'قسم الاستيراد', id :'5' },
      ]
        }
    }, 
 
      mounted() {
     //inti tooltip
     Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
        .forEach(tooltipNode => new Tooltip(tooltipNode))


     
        },
    methods:{
changeStatusStep(statuss) {
    if(statuss == '[]'){
        this.$emit('changeStatusStep' , statuss)
    }
  for (const item of this.form.workflow.steps) {
        if(item.emails[0] != undefined ||  item.start_department.department_ids[0] != undefined){
            if(item.assign_to == "users" && item.emails[0] != undefined){
            console.log(item.emails[0]);
            this.$emit('changeStatusStep' , statuss)
              item.haveEmpty = '';
            }
             if(item.assign_to == "department" &&  item.start_department.department_ids[0] != undefined){
            console.log(item.start_department.department_ids[0]);
            this.$emit('changeStatusStep' , statuss)
              item.haveEmpty = '';
        
            }
           
        }
         else{
            statuss = '[1]'
                this.$emit('changeStatusStep' , statuss)
           item.haveEmpty = 'yes';
         
           toaster.warning(`يجب عليك اختيار المستخدمين`);
         
         
      }
    
   
    
  }
           
      },
        addStep(typeStep , index){
           this.lengthstep  = this.lengthstep  + 1;
            if(typeStep == "approval"){
                this.typeStep = "approval",
                this.titleStep = "عنوان خطوة موافقة افتراضي",
                this.optionsStep =  {
              "when_aproval":{
                "label":"اذا تمت الموافقة",
                "selected": ["0"],
                "choices":[
                  {
                  "title":"الذهاب الى الخطوة التالية",
                  "value":"0"
                  },
                  {
                  "title":"الاشارة لمستخدم باعطاء ملاحظة",
                  "value":"1",
                  "emails":[]
                  },
                  {
                  "title":"ارسال بريد وتوقف المسار",
                  "value":"2"
                  }
                ]
              },
              "when_not_aproval":{
                "label":"اذا لم يتم الموافقة",
                "selected": ["0"],
                "choices":[
                  {
                  "title":"الذهاب الى الخطوة التالية",
                  "value":"0"
                  },
                  {
                  "title":"الاشارة لمستخدم باعطاء ملاحظة",
                  "value":"1",
                  "emails":[]
                  },
                  {
                  "title":"ارسال بريد وتوقف المسار",
                  "value":"2",
                  "name":"choice_not_0"
                  }
              ]
              },
              "reminder":{
                "label":"التذكير قبل انتهاء الوقت",
                "selected": ["24" ,"48"],
                "choices":[
                  {
                  "title":"قبل 72 ساعة",
                  "value":"72"
                  },
                  {
                  "title":"قبل 48 ساعة",
                  "value":"48"
                  },
                  {
                  "title":"قبل 24 ساعة",
                  "value":"24"
                  },
                  {
                  "title":"قبل 12 ساعة",
                  "value":"12"
                  }
                ]
              },
              "cc":{
                "label": "يمكنك تحديد بريد الالكتروني يصل له حاله",
                "email" :""
    
              }
          }
            }
            else{
                this.typeStep = "note",
                this.titleStep = "عنوان خطوة كتابة ملاحظة افتراضي",
                this.optionsStep =   {
              "when_add_note":{
                "label":"اذا تم كتابة ملاحظة",
                "selected": ["0"],
                "choices":[
                  {
                  "title":"الذهاب الى الخطوة التالية",
                  "value":"0"
                  },
                  {
                  "title":"الاشارة لمستخدم بكتابة ملاحظة",
                  "value":"1",
                  "emails":[]
                  },
                  {
                  "title":"ارسال بريد وتوقف المسار",
                  "value":"2"
                  }
              ]
              },
              "when_not_add_note":{
                "label":"اذا لم يتم كتابة ملاحظة",
                "selected": ["0"],
                "choices":[
                  {
                  "title":"الذهاب الى الخطوة التالية",
                  "value":"0"
                  },
                  {
                  "title":"الاشارة لمستخدم باعطاء ملاحظة",
                  "value":"1",
                  "emails":[]
                  },
                  {
                  "title":"ارسال بريد وتوقف المسار",
                  "value":"2"
                  }
              ]
              },
              "reminder":{
                "label":"التذكير قبل انتهاء الوقت",
                "selected": ["24" ,"48"],
                "choices":[
                  {
                  "title":"قبل 72 ساعة",
                  "value":"72"
                  },
                  {
                  "title":"قبل 48 ساعة",
                  "value":"48"
                  },
                  {
                  "title":"قبل 24 ساعة",
                  "value":"24"
                  },
                  {
                  "title":"قبل 12 ساعة",
                  "value":"12"
                  }
                ]
              },
              "cc":{
                "label": "يمكنك تحديد بريد الالكتروني يصل له حاله",
                "email" :""
    
              }
          }
            }
            this.form.workflow.steps.push({
                type : this.typeStep,
                step_number : index,
                title: this.titleStep,
                status: 0,
                assign_to:null,
                start_department : {
                department_ids : []
                }, 
                emails:[],
                have_deadline:null,
                deadline:null,
                haveEmpty:null,
                options:this.optionsStep
            });
        },
          removeStep:function(step) {
        //   this.$delete(this.queue, index);
            this.form.workflow.steps.splice(this.form.workflow.steps.indexOf(step), 1);
        },

    }
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
    .work-flow-steps > li{
        display: block !important;
    }
   
</style>